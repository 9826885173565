import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';
import { reactAI } from 'react-appinsights';
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

// App insight config
let appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTKEY,
    extensions: [reactAI],
    extensionConfig: {
      [reactAI.extensionId]: {
        debug: false,
        history: history
      }
    }
  }
});

appInsights.loadAppInsights();
appInsights.trackPageView();


ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
